<template>
    <mds-list-group-item
        :text="titleText"
        :active="$route.path == routeString"
        :to="routeString"
    >
    </mds-list-group-item>
</template>

<script>
import { MdsListGroupItem } from '@mds/list-group';
import NavItemMixin from './nav-item-mixin';
import RouteProcessor from '../../utils/route-processor.js';

export default {
  name: 'nav-single-item',
  props: {
      // The props are set up this way to handle several cases.
      // For versioned items, the 'baseRoute' will include the content type and the version string
      // (and a component name, if this is a single item in a sublist). For a top-level non-versioned item,
      // the base route will include only the component type.
    baseRoute: String,
    regions: Array,
  },
  components: {
    MdsListGroupItem,
  },
  mixins: [NavItemMixin],
  computed: {
    routeString() {
        // It would be more performant to create this once on created(), but created()
        // doesn't get called when the nav data changes, but the same item type is in
        // the same place in the new nav data. But the props do change. So it's either
        // generate the route string dynamically on demand, or watch the props.
        return `${this.baseRoute}/${RouteProcessor.getAlias(this.text)}`;
    },
  },
};
</script>
