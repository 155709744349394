<template>
  <mds-page-shell
    logo-alt="Back to home page"
    logo-href="/"
    logo-responsive="/logo.svg"
    logo="/logo.svg"
    :to="HOME_URL"
  >
    <page-not-found :message="message"></page-not-found>
    <site-footer></site-footer>
  </mds-page-shell>
</template>
<script>
import MdsPageShell from "@mds/page-shell";
import PageNotFound from "./page-not-found.vue";
import SiteFooter from './site-footer.vue';

export default {
  name: "new-page-not-found",
  components: {
    MdsPageShell,
    PageNotFound,
    SiteFooter,
  },
  data() {
    return {
        message: "Check your URL or click on the Morningstar logo in the header to go to the Morningstar Developer home page.",
    }
  }
};
</script>
