<template>
    <div class="footer-container___devsite">
      <div class="footer-logo">
        <img
          alt="Morningstar Logo"
          src="/logo.svg"
          class="mds-footer__logo"
        />
      </div>
      <div class="footer-text">
        &copy;{{ new Date().getFullYear() }} <a href="https://www.morningstar.com/company" target="_blank">Morningstar, Inc.</a> All rights reserved.
        <a href="https://www.morningstar.com/company/privacy-policy" target="_blank">Terms of Use</a>
      </div>
    </div>
</template>
<script>
export default {
  name: 'site-footer'
}
</script>
<style lang="scss">
@import '../style/components/site-footer';
</style>
