// Forked from https://github.com/einice/markdown-it-prism-vue

import Prism from 'prismjs';
import 'prismjs/plugins/custom-class/prism-custom-class';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';

// Languages we want to use
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-js-extras';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-css-extras';
import 'prismjs/components/prism-scss';

const DEFAULTS = {
  plugins: [],
  init: () => {},
};

/**
 * Loads the provided <code>lang</code> into prism.
 *
 * @param <String> lang
 *		Code of the language to load.
 * @return <Object?> The prism language object for the provided <code>lang</code> code. <code>undefined</code> if the code is not known to prism.
 */
function loadPrismLang(lang) {
  try {
    return Prism.languages[lang];
  } catch (e) {
    // nothing to do
  }
}

/**
 * Highlights the provided text using Prism.
 *
 * @param <String> text
 * 		The text to highlight.
 * @param <String> lang
 *		Code of the language to highlight the text in.
 * @return <String> If Prism can highlight <code>text</code> in using <code>lang</code>, the highlighted code. Unchanged <code>text</code> otherwise.
 */
function highlight(text, lang) {
  const language = lang ? lang : 'js';
  const prismLang = loadPrismLang(language);
  const highlightText = Prism.highlight(text, prismLang);

  return highlightText;
}

function markdownItPrism(markdownit, useroptions) {
  const options = Object.assign({}, DEFAULTS, useroptions);

  // CSS Modules
  Prism.plugins.customClass.map(options.styles);

  // Init
  options.init(Prism);

  // register ourselves as highlighter
  markdownit.options.highlight = highlight;
}

export default markdownItPrism;