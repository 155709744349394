<template>
  <nav
    aria-label="Page navigation"
    :class="$style['nav-list']"
    @click="onNavListSectionClick">
    <template v-for="item in navData">
      <mds-section
        v-if="item.section"
        :class="sectionClass"
        :key="`section${item.section}`"
        :bold="true"
        :size="8"
        :title="item.section"
        border="none"
      >
        <nav-section :key="item.section" :section="item.section" :initialItems="item.items" :contentType="contentType" />
      </mds-section>
    </template>
  </nav>
</template>

<script>
import MdsSection from '@mds/section';
import NavSection from './nav-section.vue';
import { HOME_URL, SECTION_ITEM_TYPE } from '../../data/constants';
import RouteProcessor from '../../utils/route-processor.js';

export default {
  name: 'nav-list',
  props: {
    navData: Array,
    contentType: String,
    sectionClass: {
      type: String,
      default: "section"
    }
  },
  components: {
    MdsSection,
    NavSection,
  },
  methods: {
    onNavListSectionClick(event) {
      const clickedElement = event.target,
      matchingChild = clickedElement.closest('.mds-section__header-container___devsite')
      if (matchingChild) {
        this.navigateToFirstSectionItem(matchingChild);
      }
    },
    navigateToFirstSectionItem(sectionHeader) {
      const sectionIndex = this.navData.map(navSection => navSection.section).indexOf(sectionHeader.textContent);
      const navSection = this.navData[sectionIndex];
      const firstSectionItem = navSection.items[0];
      let routeString = `${HOME_URL}/${this.contentType}/${RouteProcessor.getAlias(navSection.section)}/${RouteProcessor.getAlias(firstSectionItem.text)}`;
      if (firstSectionItem.sectionItemType === SECTION_ITEM_TYPE.VERSIONED_SUBLIST) {
        const versions = firstSectionItem.versions;
        routeString += `/${RouteProcessor.getAlias(versions[0].sublist[0].text)}`;
      } else if (firstSectionItem.sectionItemType === SECTION_ITEM_TYPE.SUBLIST) {
        routeString += `/${RouteProcessor.getAlias(firstSectionItem.sublist[0].text)}`;
      }
      this.$router.push({ path: routeString });
    },
  },
};
</script>
<style module lang="scss">
@import '../../style/components/nav-list';
</style>