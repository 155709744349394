<template>
    <div :class="$style['media-carousel']">
        <item-description :description="slides" :class="$style['media-carousel__preload-all-slides']"/>
        <transition-group
            ref="slider"
            tag="div"
            :name="'slide-' + transitionName"
            :class="$style['media-carousel__slider']"
            @before-leave="onBeforeLeave">
            <div
                v-for="number in [activeSlide]"
                :key="number"
                :class="$style['media-carousel__slide']">
                <mds-layout-grid v-if="typeof slides[activeSlide] != 'string'">
                    <mds-row>
                        <mds-col cols="12" cols-at-m="3">
                            <item-description
                                :description="slides[activeSlide].description"
                                :class="$style['media-carousel__slide-description']"
                            ></item-description>
                        </mds-col>
                        <mds-col cols="12" cols-at-m="9">
                            <item-description
                                :description="[slides[activeSlide].slide]"
                                @component-rendered.once="onComponentRendered"
                            ></item-description>
                        </mds-col>
                    </mds-row>
                </mds-layout-grid>
                <item-description
                    v-else
                    :description="[slides[activeSlide]]"
                    @component-rendered.once="onComponentRendered"
                ></item-description>
            </div>
        </transition-group>
        <mds-button-container :class="$style['media-carousel__controls']">
            <mds-button
                type="button"
                variation="icon-only"
                icon="caret-circle-left"
                size="touch"
                :class="$style['media-carousel__prev-btn']"
                @click="showPrevSlide"
            >Previous</mds-button>
            <mds-button
                variation="icon-only"
                size="small"
                :icon="playing ? 'pause-circle' : 'play-circle'"
                type="button"
                @click="togglePlay"
            >{{ playing ? 'Stop animation' : 'Start animation' }}</mds-button>
            <mds-button
                v-for="(slide, index) in slides"
                :key="index"
                :class="[$style['media-carousel__nav-button'], { [$style['media-carousel__nav-button--active']]: index === activeSlide }]"
                variation="icon-only"
                size="small"
                icon="dot"
                @click="showSlide(index)"
            >View media at index {{ index + 1 }}</mds-button>
            <mds-button
                type="button"
                variation="icon-only"
                icon="caret-circle-right"
                size="touch"
                :class="$style['media-carousel__next-btn']"
                @click="showNextSlide"
            >Next</mds-button>
        </mds-button-container>
    </div>
</template>
<script>
import ItemDescription from './items/item-description.vue';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";

export default {
    components: {
        ItemDescription,
        MdsButton,
        MdsButtonContainer,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
    },
    props: {
        slides: [],
    },
    data() {
        return {
            activeSlide: 0,
            interval: 10000, // 10 seconds
            playing: true,
            timer: null,
            transitionName: 'left',
            videoElements: [],
        };
    },
    created() {
        this.resetTimer();
    },
    beforeDestroy() {
        this.detachVideoListeners();
        this.clearTimer();
    },
    methods: {
        attachVideoListeners(el) {
            this.videoElements = el.querySelectorAll('video') || [];
            for (let index = 0; index < this.videoElements.length; index++) {
                this.videoElements[index].addEventListener('play', this.stopAnimation);
            }
        },
        clearTimer() {
            if (this.timer) {
                window.clearInterval(this.timer);
            }
        },
        detachVideoListeners() {
            for (let index = 0; index < this.videoElements.length; index++) {
                this.videoElements[index].removeEventListener('play', this.stopAnimation);
            }
            this.videoElements = [];
        },
        onBeforeLeave() {
            this.detachVideoListeners();
        },
        onComponentRendered() {
            this.attachVideoListeners(this.$refs.slider.$el);
        },
        resetTimer() {
            this.clearTimer();
            if (this.playing) {
                this.timer = window.setInterval(this.showNextSlide, this.interval);
            }
        },
        showPrevSlide() {
            this.transitionName = 'right';
            this.activeSlide = (this.activeSlide + this.slides.length - 1) % this.slides.length;
            this.resetTimer();
        },
        showNextSlide() {
            this.transitionName = 'left';
            this.activeSlide = (this.activeSlide + 1) % this.slides.length;
            this.resetTimer();
        },
        showSlide(index) {
            this.transitionName = this.activeSlide > index ? 'right' : 'left';
            this.activeSlide = index;
            this.resetTimer();
        },
        stopAnimation() {
            this.playing = false;
            this.clearTimer();
        },
        togglePlay() {
            this.playing = !this.playing;
            this.resetTimer();
        },
    },
};
</script>
<style lang="scss" module>
@import '@mds/constants';

$slide-height: 650px;
$slide-height-mobile: 350px;
.media-carousel {
    overflow: hidden;

    &__preload-all-slides {
        position: absolute;
        width: 1px;
        height: 1px;
        visibility: hidden;
        overflow: hidden;
    }

    :global(.slide-left-leave-active),
    :global(.slide-left-enter-active),
    :global(.slide-right-leave-active),
    :global(.slide-right-enter-active) {
        transition: 0.5s;
    }
    :global(.slide-left-enter) {
        margin-left: 0;
    }
    :global(.slide-left-leave-to) {
        margin-left: -50%;
    }

    :global(.slide-right-enter),
    :global(.slide-right-enter-to) {
        margin-left: -100%;
    }
    :global(.slide-right-leave-to) {
        margin-left: 50%;
    }

    &__slider {
        overflow-x: hidden;
        position: relative;
        width: 200%;

        :global(.item-description) {

            p {
                max-width: unset;
            }

            img, video {
                display: block;
                margin: 0 auto;
                max-height: $slide-height-mobile;
                max-width: 100%;

                @media screen and (min-width: 600px) {
                    max-height: $slide-height;
                }

                * {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    &__slide {
        float: left;
        width: 50%;
        min-height: $slide-height-mobile + $mds-space-2-x;
        padding: $mds-space-2-x 0 0;

        @media screen and (min-width: 600px) {
            min-height: $slide-height + $mds-space-4-x + $mds-space-4-x;
            padding: $mds-space-4-x;
        }

        &-description {
            text-align: left;

            h3 {
                margin: 0;

                &::before {
                    height: 0;
                    margin: 0;
                }
            }
        }
    }

    &__controls {
        justify-content: center;
    }

    &__nav-button {
        .mds-icon {
            width: $mds-space-1-x;
            height: $mds-space-1-x;
            background-color: $mds-color-neutral-67;
            border-radius: $mds-border-radius-round;

            &:hover {
                background-color: $mds-color-neutral-50;
            }
        }

        &--active .mds-icon {
            background-color: $mds-color-neutral-37;
        }
    }
}
</style>
