<template>
  <div id="swagger-ui-container"></div> 
</template>

<script>
import { SwaggerUIBundle } from 'swagger-ui-dist';
import 'swagger-ui-dist/swagger-ui.css';
import TokenStorageService from '../utils/token-storage-service.js'
import axios from 'axios';
import yaml from 'js-yaml';

export default {
  name: 'swagger-ui-wrapper',
  props: {
    url: String
  },
  data() {
    return {
        uiRef: null,
        useOAS2: false,
        securitySchemaNames: []
    };
  },
  async mounted() {
    await this.createSwaggerUI(this.url);
  },
  beforeUnmount() {
    TokenStorageService.Unsubscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_CHANGED, this.handleTokenChanged);
    this.uiRef = null;
  },
  methods: {
    async createSwaggerUI(url) {

      let token = TokenStorageService.GetSelectedToken();

      this.uiRef = null;
      this.useOAS2 = false;
      this.securitySchemaNames = [];

      const response = await axios.get(url, {responseType: 'text', transformResponse: res => res});

      try {
        const oasDoc = yaml.load(response.data);

        // Parse out the names of the security schemes, so that we can pre-authorize them with the dev site's auth token
        if(oasDoc?.swagger?.startsWith('2')) {
          this.useOAS2 = true;
          if('securityDefinitions' in oasDoc) {
            for(const schemeName in oasDoc.securityDefinitions) {
              if(oasDoc.securityDefinitions[schemeName].type === 'apiKey' 
                && oasDoc.securityDefinitions[schemeName].in === 'header'
                && oasDoc.securityDefinitions[schemeName].name === "Authorization") {
                  this.securitySchemaNames.push(schemeName);
              }
            }
          }
        } else if(oasDoc?.components?.securitySchemes) {
            for(const schemeName in oasDoc.components.securitySchemes) {
              if(oasDoc.components.securitySchemes[schemeName].type === 'http' && oasDoc.components.securitySchemes[schemeName].scheme === 'bearer') {
                this.securitySchemaNames.push(schemeName);
              }
            }
        }

      } catch(e) {
        console.log(e);
      }

      const ui = SwaggerUIBundle({
        dom_id: '#swagger-ui-container',
        url: url,

        onComplete: function() {
          
          if(token) {
            this.preauthorizeKeys(ui, token);
          }

          this.uiRef = ui;
          TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_CHANGED, this.handleTokenChanged);
        }.bind(this),

        // If we need to handle authorization declared as api parameters, use the parameterMacro,
        // check for name = "authorization" and in = "header", then set example to bearer + token
        // This only works for OAS3, and was removed because it may interfere with a basic auth scheme
        // set up the same way

      });
    },
    preauthorizeKeys(ui, token) {
      const versionToken = this.useOAS2 ? `Bearer ${token}` : token;
      for(const schemaName of this.securitySchemaNames) {
        ui.preauthorizeApiKey(schemaName, versionToken);
      }
    },
    handleTokenChanged() {
      if(this.uiRef != null) {
        const newToken = TokenStorageService.GetSelectedToken();
        this.preauthorizeKeys(this.uiRef, newToken);
      }
    },
  },
  watch: {
    url: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.createSwaggerUI(newVal)
      }
    }
  }
}
</script>

<style lang="scss">
/* Hide the servers dropdown and Authorize button */
#swagger-ui-container {

  .scheme-container, .try-out {
    display: none;
  }
  
}
</style>