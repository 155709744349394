<template>
    <nav-single-item v-if="item.sectionItemType === SECTION_ITEM_TYPE.SINGLE_LINK"
        :baseRoute="baseRoute"
        :region="region"
        :regions="item.regions"
        :text="itemText"
    />

    <nav-versioned-single-item v-else-if="item.sectionItemType === SECTION_ITEM_TYPE.VERSIONED_SINGLE_LINK"
        :contentType="contentType"
        :region="region"
        :section="section"
        :text="itemText"
        :versions="item.versions"
    />

    <nav-versioned-sublist v-else-if="item.sectionItemType === SECTION_ITEM_TYPE.VERSIONED_SUBLIST"
        :baseRoute="baseRoute"
        :contentType="contentType"
        :region="region"
        :section="section"
        :text="itemText"
        :versions="item.versions"
        />

    <nav-sublist v-else-if="item.sectionItemType === SECTION_ITEM_TYPE.SUBLIST"
        :baseRoute="baseRoute"
        :contentType="contentType"
        :itemSublist="item.sublist"
        :region="region"
        :section="section"
        :text="itemText"
    />
</template>
<script>
import NavItemMixin from './nav-item-mixin';
import NavSingleItem from './nav-single-item.vue';
import NavSublist from './nav-sublist';
import NavVersionedSingleItem from './nav-versioned-single-item.vue';
import NavVersionedSublist from './nav-versioned-sublist.vue';
import { REGION, SECTION_ITEM_TYPE } from '../../data/constants';

const regionAbbrMapping = {
    emea: 'APAC/EMEA',
    na: 'Americas',
};

export default {
    name: 'nav-section-item',
    components: {
        NavSingleItem,
        NavSublist,
        NavVersionedSingleItem,
        NavVersionedSublist,
    },
    mixins: [NavItemMixin],
    props: {
        baseRoute: String,
        contentType: String,
        region: String,
        section: String,
        item: Object,
    },
    data() {
        return {
            toggleActive: false
        };
    },
    computed: {
        itemText() {
            return this.region === REGION.ALL ? this.item.text : regionAbbrMapping[this.region];
        },
    },
    created() {
        this.REGION = REGION;
        this.SECTION_ITEM_TYPE = SECTION_ITEM_TYPE;
    },
}
</script>
