<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.matched[0].meta.title || 'Morningstar Developer';
      }
    }
  }
}
</script>