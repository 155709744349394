<template>
  <iframe :id="iframeId" :src="iframeUrl" frameborder="0" ref="iframeref" name="iframeWrapper"></iframe>
</template>

<script>
import TokenStorageService from '../utils/token-storage-service.js';

export default {
  name: 'iframe-wrapper',
  props: {
    iframeId: String,
    iframeUrl: String
  },
  data() {
    return {
        sendUpdatesToChild: false,
        savedEvent: null
    };
  },
  mounted() {
    window.addEventListener('message', this.messageHandler);
    TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_CHANGED, this.handleTokenChanged);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.messageHandler);
    TokenStorageService.Unsubscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_CHANGED, this.handleTokenChanged);
  },
  methods: {
    messageHandler(event) {
      const { action, tokenName } = event.data;
      if (action === 'getToken') {

        const token = TokenStorageService.GetSelectedToken(tokenName);
        event.source.postMessage({
          action: 'setToken',
          value: token
        }, '*');

      } else if (action === 'reloadFrame') {

        this.reloadIFrame();

      } else if (action === 'beginTokenUpdates') {
        this.savedEvent = event;
        this.sendUpdatesToChild = true;

      } else if (action === 'stopTokenUpdates') {
        this.savedEvent = null;
        this.sendUpdatesToChild = false;

      }
    },
    handleTokenChanged() {
      if(this.sendUpdatesToChild && this.savedEvent) {
        const target = this.savedEvent.source;

        if(target) {
          const newToken = TokenStorageService.GetSelectedToken(this.savedEvent?.data.tokenName);
          target.postMessage({
            action: 'updateToken',
            value: newToken
          }, '*');
        }
      }
    },
    reloadIFrame() {
      // Note there are many ways to reload and iframe, which differ in availability
      // by browser and version. This may need to be expanded. 
      const theUrl = this.$refs.iframeref?.src;
      if(theUrl) {
        this.$refs.iframeref.src = theUrl;
      }
    },
  }
}
</script>
