<template>
    <mds-page-shell
      hidden-menu
      hidden-menu-hide-wide
      logo-alt="Back to home page"
      logo-responsive="/logo-brand.svg"
      logo="/logo-brand.svg"
      persistent-menu
      :to="HOME_URL"
      :sticky="true"
    >

    <template #mds-page-shell-masthead-left>
      <div class="masthead-left__logo-wrap">
        <a href="/direct-web-services" class="router-link-active mds-page-shell__masthead-link___devsite">
          <img src="/logo-brand.svg" alt="Back to home page" class="mds-page-shell__masthead-logo-wide___devsite">
          <img src="/logo-brand.svg" alt="Back to home page" class="mds-page-shell__masthead-logo___devsite">
          <span>Morningstar for Developers</span>
        </a>
      </div>
    </template>

    <template v-slot:mds-page-shell-vertical-nav>
      <nav-list-search v-if="showSideNav" :navData="navData" :contentType="contentType" class="side-nav" />
    </template>

    <template v-slot:mds-page-shell-hidden-nav>
      <hidden-nav />
    </template>

    <template v-slot:mds-page-shell-masthead-right>
      <div class="masthead-right__nav">
        <mds-tabs
          variation="site-navigation"
          alignment="center"
          size="medium"
          :content="tabsSiteNavContent"
          v-on:mds-tabs-item-active="handleTabActiveEvent" />
      </div>
      <token-dialog />
    </template>

    <router-view> </router-view>

    <template>
        <site-footer />
    </template>
  </mds-page-shell>
</template>

<script>
import MdsPageShell from '@mds/page-shell';
import MdsTabs from '@mds/tabs';
import { CONTENT_TYPE, HOME_URL } from '@/data/constants';
import NavDataService from '../data/nav-data.service.js';
import NavListSearch from './nav-list/nav-list-search.vue';
import HiddenNav from './hidden-nav.vue';
import TokenDialog from './token-dialog.vue';
import SiteFooter from './site-footer.vue';

export default {
  name: 'landing-page',
  components: {
    MdsPageShell,
    MdsTabs,
    NavListSearch,
    HiddenNav,
    TokenDialog,
    SiteFooter
  },
  data() {
    return {
      // This is the content that defines the left vertical nav menu.
      // It is filtered by the searchValue.
      // The menu is actually rendered based on filteredNavData
      navData: [],

      // This is which content area is selected (documentation, api-visualization-library)
      // Its value should be of CONTENT_TYPE
      contentType: '',

      HOME_URL,

      // Note that we MUST give each entry a  unique 'id' field for the mds-tabs-item-active event
      // to work. Since this field is mandatory, we use content type (since we need that data anyway)
      tabsSiteNavContent: [
        {
          id: CONTENT_TYPE.SOLUTIONS,
          text: 'Solutions',
          active: false,
        },
        {
          id: CONTENT_TYPE.USE_CASES,
          text: 'Use Cases',
          active: false,
        },
        {
          id: CONTENT_TYPE.DOCUMENTATION,
          text: 'Documentation',
          active: false,
        },
        {
          id: CONTENT_TYPE.API_VISUALIZATION_LIBRARY,
          text: 'API Visualization Library',
          active: false,
        },
        {
          id: CONTENT_TYPE.PARTNERS,
          text: 'Partners',
          active: false,
        },
      ],
    };
  },
  computed: {
    isLandingPage() {
      return [
        `${HOME_URL}/documentation/documentation/about`,
      ].indexOf(this.$route.path) > -1;
    },
    showSideNav() {
      return !this.isLandingPage && ['', 'partners', 'use-cases', 'api-visualization-library'].indexOf(this.contentType) === -1
    },
  },
  async created() {
    if (localStorage.getItem('showOldTabs') === 'true') {
      this.tabsSiteNavContent.push({
        id: CONTENT_TYPE.APIS,
        text: 'APIs',
        active: false
      });
      this.tabsSiteNavContent.push({
        id: CONTENT_TYPE.COMPONENTS,
        text: 'Components',
        active: false
      });
    }
    // Set the active tab - need to handle at construction for e.g. deeplinks
    await this.setActiveTabFromRoutePath(this.$route.path);
  },
  methods: {
    async setActiveTabFromRoutePath(routePath) {
      // Parse the content type from the route string
      // I sort of hate this, but we don't get the content area type (documentation / api-visualization-library)
      // any other way.
      if (routePath === HOME_URL || routePath === HOME_URL + '/') {
        this.contentType = '';
        this.setActiveTab();
        return;
      }
      for (const pageContentType of Object.values(CONTENT_TYPE)) {
        if (routePath.startsWith(`${HOME_URL}/${pageContentType}`)) {
          await this.setActiveTab(pageContentType);
          break;
        }
      }
    },
    async setActiveTab(pageContentType) {
      for (let item of this.tabsSiteNavContent) {
        if (item.id === pageContentType) {
          if (item.active === false) {
            item.active = true;
            const tmpData  = await NavDataService.GetNavData(item.id);
            this.navData = tmpData;
            this.contentType = item.id;
          }
        } else {
          item.active = false;
        }
      }
    },
    handleTabActiveEvent(event) {
      // This is the handler when one of the tabs is clicked
      // Tell the content window to render the default for the selected space
      // This will trigger the route watch below, which will change the visibly active tab.
      // We do things this way so that the order of data changes will be consistent
      // (first the route changes, then the navData changes)
      this.$router.push({ path: `${HOME_URL}/${event.currentTarget.id}/` });
    }
  },
  watch: {
    $route: function(to) {
      // This handles the 'back' button, making sure the right tab / left nav content is selected
      this.setActiveTabFromRoutePath(to.path).then();
    },
  }
}
</script>
<style lang="scss">
@import '../style/components/landing-page';
</style>
