<template>
    <div>
        <!-- <mds-search-field class="search" v-model="searchValue" /> -->
        <div v-if="isFilteredNavDataEmpty">
            <span class="noSearchResult">No Search Results</span>
        </div>
        <div v-else class="nav-list--clickable-section-header">
            <nav-list :navData="filteredNavData" :contentType="contentType" />
        </div>
    </div>
</template>


<script>
// import MdsSearchField from '@mds/search-field';
import NavList from './nav-list';
import { debounce } from 'lodash';
import { CONTENT_TYPE } from '@/data/constants';

export default {
  name: 'nav-list-search',
  components: {
    // MdsSearchField,
    NavList,
  },
  props: {
    navData: Array,
    contentType: CONTENT_TYPE,
  },
  data() {
    return {
      searchValue: '',
      filteredNavData: [],
    };
  },
  async created() {
      this.filteredNavData = this.navData;
      this.filterNavDataWithDebounce = debounce(this.filterNavData, 300);
  },
  computed: {
    isFilteredNavDataEmpty() {
      return this.filteredNavData.length === 0;
    },
  },
  methods: {
    filterNavData: function() {

      let navData = [];

      this.navData.forEach(section => {
        const navItems = section.items.filter(item => {
          return item.text.toLowerCase().includes(this.searchValue.toLowerCase());
        });
        if (navItems.length > 0) {
          const sectionCopy = {};
          Object.assign(sectionCopy, section);
          sectionCopy.items = navItems;
          navData.push(sectionCopy);
        }
      });

      this.filteredNavData = navData;
    },
  },
  watch: {
    searchValue: function() {
      this.filterNavDataWithDebounce();
    },
    navData: function() {
        // This catches the case where the user clicks a nav tab and changes the entire nav data
        // We could just clear the searchValue and let the watch on it re-filter the
        // nav data, but that introduces noticeable delay in the UI
        this.searchValue = '';
        this.filterNavData();
    }
  }
}
</script>
