import Vue from 'vue';
import VueRouter from 'vue-router';

import ContentFrame from '@/components/content-frame';
import HomePage from '@/components/home-page';
import Partners from '@/components/partners';
import LandingPage from '@/components/landing-page';
import NewLandingPage from '@/components/new-landing-page';
import NewPageNotFound from '@/components/new-page-not-found';
import PageNotFound from '@/components/page-not-found';
import UseCases from '@/components/use-cases';
import BecomePartner from '@/components/become-a-partner';
import FindPartner from '@/components/find-a-partner';

import { CONTENT_TYPE, HOME_URL, HOME_URL_V0, SOLUTIONS_PAGE_URL, TEAM_NAME } from '../data/constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: NewLandingPage,
  },
  {
    path: `${HOME_URL_V0}/${CONTENT_TYPE.DOCUMENTATION}/dynamic-services-apis/*`,
    redirect: `${HOME_URL}/${CONTENT_TYPE.DOCUMENTATION}/direct-web-services/*`,
  },
  {
    path: `${HOME_URL_V0}*`,
    redirect: `${HOME_URL}*`,
  },
  {
    path: HOME_URL,
    component: LandingPage,
    meta: { title: TEAM_NAME },
    children: [
      {
        path: '',
        component: HomePage,
      },
      {
        path: `${CONTENT_TYPE.SOLUTIONS}`,
        beforeEnter() {
          window.open(SOLUTIONS_PAGE_URL, '_blank');
        }
      },
      {
        path: `${CONTENT_TYPE.COMPONENTS}`,
        redirect: `${CONTENT_TYPE.COMPONENTS}/getting-started/morningstar-components`,
      },
      {
        path: `${CONTENT_TYPE.APIS}`,
        redirect: `${CONTENT_TYPE.APIS}/getting-started/morningstar-apis`,
      },
      {
        path: `${CONTENT_TYPE.DOCUMENTATION}`,
        redirect: `${CONTENT_TYPE.DOCUMENTATION}/overview/about`,
      },
      {
        path: `${CONTENT_TYPE.DOCUMENTATION}/api-reference/*`,
        redirect: `${CONTENT_TYPE.DOCUMENTATION}/direct-web-services/*`,
      },
      {
        path: `${CONTENT_TYPE.DOCUMENTATION}/dynamic-services-apis/*`,
        redirect: `${CONTENT_TYPE.DOCUMENTATION}/direct-web-services/*`,
      },
      {
        path: 'developer-resources*',
        redirect: `${CONTENT_TYPE.API_VISUALIZATION_LIBRARY}*`,
      },
      {
        path: `${CONTENT_TYPE.API_VISUALIZATION_LIBRARY}`,
        redirect: `${CONTENT_TYPE.API_VISUALIZATION_LIBRARY}/api-visualization-library/api-visualization-library/page`,
      },
      {
        path: `${CONTENT_TYPE.PARTNERS}`,
        component: Partners,
      },
      {
        path: `${CONTENT_TYPE.PARTNERS}/find-a-partner`,
        component: FindPartner,
      },
      {
        path: `${CONTENT_TYPE.PARTNERS}/become-a-partner`,
        component: BecomePartner,
      },
      {
        path: `${CONTENT_TYPE.USE_CASES}/:caseId?`,
        component: UseCases,
        props: route => ({
          caseId: route.params.caseId,
        }),
      },
      {
        path: `:contentType/:sectionId/:contentId?/:subContentId?`,
        component: ContentFrame,
        props: route => ({
          contentType: route.params.contentType,
          sectionId: route.params.sectionId,
          contentId: route.params.contentId,
          subContentId: route.params.subContentId,
        }),
      },
      {
        path: "*",
        name: "Page Not Found",
        component: PageNotFound,
      },
    ],
  },
  {
    path: '*',
    component: NewPageNotFound,
  }
];

if(localStorage.getItem('showOldTabs') !== 'true') {
  routes[3].children.splice(routes.length-2, 0, {
      path: `${CONTENT_TYPE.COMPONENTS}/*`,
      component: PageNotFound
    },
    {
      path: `${CONTENT_TYPE.APIS}/*`,
      component: PageNotFound
    }
  )
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function patchRouterMethod (router, methodName) {
  router['old' + methodName] = router[methodName]
  router[methodName] = async function (location) {
    return router['old' + methodName](location).catch((error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute;
      }
      throw error;
    });
  }
}

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

export default router;
