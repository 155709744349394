<template>
  <div :class="$style['find-a-partner']">
    <div :class="$style['find-a-partner__back-btn']">
      <mds-button
        variation="flat"
        icon="caret-left"
        :to="HOME_URL + '/partners'"
      >Back to Partners</mds-button>
    </div>
    <mds-layout-grid class="mds-doc-typography___devsite" v-if="itemsData">
      <template>
        <mds-row>
          <mds-col :cols-at-s="12" :cols-at-l="8">
            <h1 :class="$style['find-a-partner__title']">{{ itemsData.name }}</h1>
            <item-description :description="itemsData.description" :class="$style['find-a-partner__overview']"></item-description>
          </mds-col>
        </mds-row>
        <br/>
        <mds-row>
          <mds-col :cols-at-s="12" :cols-at-l="8">
            <mds-row v-for="(partner, index) in itemsData.partnersData" :key="index" :class="$style['find-a-partner__partners-list']">
              <mds-col :cols="3">
                <div :class="$style['find-a-partner__partner-image']">
                  <img :src="partner.image" alt="partner-image">
                </div>
              </mds-col>
              <mds-col :cols="9">
                <mds-row>
                  <mds-col :cols="12" :cols-at-m="9">
                    <item-description :description="partner.description" :class="$style['find-a-partner__partner-description']"></item-description>
                  </mds-col>
                  <mds-col :cols="12" :cols-at-m="3">
                    <span :class="$style['find-a-partner__partner-contact']" v-html="partner.contact"></span>
                  </mds-col>
                </mds-row>
              </mds-col>
            </mds-row>
          </mds-col>
        </mds-row>
      </template>

      <mds-row :class="$style['find-a-partner__footer-row']" v-if="itemsData.contact">
        <mds-col>
          <div :class="$style['find-a-partner__footer']">
            <item-description :description="itemsData.contact.displayText"></item-description>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>
<script>
import ItemDescription from './items/item-description.vue';
import { MdsButton } from '@mds/button';
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { HOME_URL, PARTNERS_FILE } from '../data/constants';
import axios from "axios";

export default {
  name: 'find-a-partner',
  components: {
    ItemDescription,
    MdsButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
  },
  data() {
    return {
      HOME_URL,
      itemsData: null,
      toggle: {}
    };
  },
  computed: {

  },
  methods: {
    async fetchItemsData() {
      const path = this.$route.path.split('/').pop();
      const jsonFileResponse = await axios.get(PARTNERS_FILE);
      this.itemsData = jsonFileResponse.data.items?.find(item => item.path === path);
    },
    getImageUrl(imgUrl) {
      return `/content/hidden-from-navigation/${imgUrl}`;
    }
  },
  async created() {
    await this.fetchItemsData();
  }
}
</script>
<style lang="scss" module>
@import '@mds/typography';
@import '@mds/link';

.find-a-partner {
  padding: $mds-space-4-x $mds-space-4-x 0;
  margin: auto;
  max-width: 1347px;

  .mds-button.mds-button--primary {
    &.dark-button {
      background-color: $mds-color-neutral-20;
      color: $mds-color-white;
      white-space: nowrap;
    }
  }

  &__title {
    @include mds-level-1-heading();

    color: $mds-text-color-primary;
    margin: 0 0 $mds-space-3-x;
  }

  &__overview {
    > p {
      @include mds-body-text-l();
    }

    > ol {
      @include mds-ordered-list(large)
    }
  }

  &__back-btn {
    margin-bottom: $mds-space-8-x;
  }

  &__partners-list.mds-layout-grid__row {
    padding-bottom: 6px;
    margin-bottom: 32px;
    border-bottom: 1px solid #E5E5E5;
  }

  &__partner-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items :center;
    width: 155px;
    height: 155px;
    margin: 0 auto;

    @media screen and (max-width: $mds-breakpoint-s) {
      width: auto;
      height: auto;
    }

    img {
      max-width: 155px;
      max-height: 155px;

      @media screen and (max-width: $mds-breakpoint-s) {
        max-width: 75px;
        max-height: 75px;
      }
    }
  }

  &__partner-description {
    position: relative;
    padding-bottom: 10px;
    
    h3 {
      margin-top: 0;
      @include mds-level-3-heading();

      &::before {
        content: none;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__tier {
    display: inline-block;
    
    @media screen and (min-width: $mds-breakpoint-s) {
      position: absolute;
      top: 0px;
      right: 0px;
    }
    
    .donut {
      display: inline-block;
      position: relative;
      top: 2px;
      width: 20px;
      height: 20px;
      border: 6px solid;
      border-radius: 100%;
      margin: 0 auto;

      &.basic {
        border-color: $mds-color-dark-green-34;
      }

      &.premium {
        border-color: $mds-color-blue-38;
      }

      &.elite {
        border-color: $mds-color-orange-54;
      }
    }
  }

  &__footer {
    text-align: center;
    padding: $mds-space-2-x;

    &-row {
      background-color: $mds-interactive-color-secondary-active;
    }

    & &-title {
      @include mds-level-1-heading();
      color: $mds-text-color-primary-on-dark;
      margin: $mds-space-1-x;
    }

    & &-text {
      @include mds-level-4-heading();
      color: $mds-text-color-primary-on-dark;

      &:before {
        content: none;
      }
    }

    .mds-button {
      margin: $mds-space-3-x 0 $mds-space-1-x;
    }

    .btn-link {
      margin: 0 auto;
    }
  }
}
</style>
