<template>
  <nav :class="$style['hidden-nav']">
    <mds-section :size="5" title="Solutions" border="none" @click.native="navigateTo('solutions')" :class="$style['navigable-section']"></mds-section>
    <mds-section :size="5" title="Use Cases" border="secondary" @click.native="navigateTo('use-cases')" :class="$style['navigable-section']"></mds-section>
    <template v-if="showOldTabs">
      <mds-section collapsible :size="5" title="APIs" border="secondary">
        <nav-list :navData="apisNavData" contentType="apis"/>
      </mds-section>
      <mds-section collapsible :size="5" title="Components" border="secondary">
        <nav-list :navData="componentsNavData" contentType="components"/>
      </mds-section>
    </template>
    <mds-section collapsible :size="5" title="Documentation" border="secondary">
      <nav-list :navData="documentationNavData" contentType="documentation"/>
    </mds-section>
    <mds-section :size="5" title="API Visualization Library" border="secondary" @click.native="navigateTo('api-visualization-library')" :class="$style['navigable-section']"></mds-section>
    <mds-section :size="5" title="Partners" border="secondary" @click.native="navigateTo('partners')" :class="$style['navigable-section']"></mds-section>
  </nav>
</template>

<script>
import NavList from './nav-list/nav-list.vue';
import MdsSection from '@mds/section';
import NavDataService from '../data/nav-data.service.js';
import { CONTENT_TYPE, HOME_URL, SOLUTIONS_PAGE_URL } from '@/data/constants';

export default {
  name: 'hidden-nav',
  components: {
    NavList,
    MdsSection
  },
  data() {
    return {
      apisNavData: [],
      apiVisualizationLibraryNavData: [],
      documentationNavData: [],
      componentsNavData: [],
      showOldTabs: false,
    }
  },
  computed: {
    isLandingPage() {
      return [
        `${HOME_URL}/documentation/documentation/about`,
      ].indexOf(this.$route.path) > -1;
    },
  },
  async created() {
    this.showOldTabs = localStorage.getItem('showOldTabs') === 'true';
    await this.setNavData();
  },
  methods: {
    async setNavData() {
      if (this.showOldTabs) {
        const componentsTempNavData = await NavDataService.GetNavData(CONTENT_TYPE.COMPONENTS);
        this.componentsNavData = componentsTempNavData;

        const apisTempNavData = await NavDataService.GetNavData(CONTENT_TYPE.APIS);
        this.apisNavData = apisTempNavData;
      }

      const apiVisualizationLibraryTempNavData = await NavDataService.GetNavData(CONTENT_TYPE.API_VISUALIZATION_LIBRARY);
      this.apiVisualizationLibraryNavData = apiVisualizationLibraryTempNavData;

      const documentationTempNavData = await NavDataService.GetNavData(CONTENT_TYPE.DOCUMENTATION);
      this.documentationNavData = documentationTempNavData;
    },
    navigateTo(pageName) {
      if (pageName === 'solutions') {
        window.open(SOLUTIONS_PAGE_URL, '_blank');
      }
      else {
        this.$router.push({ path: `${HOME_URL}/${pageName}` });
      }
    },
  }
}
</script>
<style module lang="scss">
@import '../style/components/hidden-nav';
</style>